.login {
  background-color: #FFFFFF;
  color: #0000FF; }
  .login #login_error {
    border: 0px solid transparent;
    border: 3px solid #dc3232;
    color: #dc3232; }
  .login .message {
    border: 0px solid transparent;
    border: 3px solid green;
    color: green; }
  .login #login {
    padding: 0; }
  .login form {
    border: 3px solid #0000FF;
    padding-bottom: 25px; }
    .login form input {
      border: 3px solid #0000FF !important;
      border-radius: 0 0 0 0;
      outline: none;
      -webkit-appearance: none;
      background-color: #FFFFFF;
      box-shadow: 0 0 0 0 transparent;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .login form input:focus, .login form input:active, .login form input:visited {
        outline: none !important;
        box-shadow: 0 0 0 2px #0000FF !important; }
    .login form #rememberme {
      width: 22px;
      height: 22px; }
    .login form .dashicons {
      color: #0000FF; }
    .login form .user-pass-wrap {
      margin-bottom: 20px; }
    .login form #wp-submit {
      color: #0000FF;
      background-color: transparent;
      font-size: 14px;
      border-radius: 0 0 0 0; }
      .login form #wp-submit:focus, .login form #wp-submit:active, .login form #wp-submit:visited, .login form #wp-submit:hover {
        background-color: transparent;
        outline: none !important;
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px #0000FF !important; }
    .login form .button-secondary.wp-hide-pw {
      padding: 0px;
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      margin: 3px; }
      .login form .button-secondary.wp-hide-pw:focus, .login form .button-secondary.wp-hide-pw:active, .login form .button-secondary.wp-hide-pw:visited {
        border-radius: 0 0 0 0;
        border: 0px solid transparent;
        box-shadow: 0 0 0 1px #0000FF !important; }
      .login form .button-secondary.wp-hide-pw:hover {
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px transparent !important; }
  .login #nav a, .login #backtoblog a {
    color: #0000FF; }
    .login #nav a:focus, .login #nav a:active, .login #nav a:visited, .login #nav a:hover, .login #backtoblog a:focus, .login #backtoblog a:active, .login #backtoblog a:visited, .login #backtoblog a:hover {
      color: #0000FF;
      box-shadow: none;
      font-weight: bold; }
  .login h1 a {
    background: url("../../img/glunz/GLUNZ_logo.svg");
    width: 200px;
    height: 90px;
    margin: 0 auto 10vh auto; }
    @media only screen and (min-width: 640px) {
      .login h1 a {
        margin: 0 auto 20vh auto; } }
